import { hasConsent, hasConsentOrNot } from "./hasConsent";
import {Groups} from "./onetrust";
import loadScript from "../utils/loadscript";
import { domContentLoaded } from "../utils/domContentLoaded";
import {curryN} from "ramda";
import waitFor from "../utils/waitFor";
import log from "../utils/log";

/////////
/////////////////////////////////////////
/////////

/**
 * Apply a function when a specific Onetrust category is accepted
 * @param {string} cat - i.e C0001
 * @param {function} fn
 * @param {array} args - Parameters for the requested function
 * @returns {Promise}
 */
const applyConsentFunc = async (cat, fn, ...args) => {
    const consent = await hasConsent(cat);
    if (consent) {
        await domContentLoaded();
        fn.apply(null, args)
    }
};

/**
 * Apply a function with ConsentResponse when a specific Onetrust category is accepted
 * @returns {Promise}
 */
 const isAprovedConsentFunc = async (cat, fn, ...args) => {

    const consentGroups = await hasConsentOrNot(cat);
    log('consentGroups', cat, consentGroups)
    if (fn && typeof fn === 'function') {

        await domContentLoaded();
        fn.apply(null, [consentGroups, ...args])
    } else {
        return !!consentGroups
    }
 }

 
/**
 * Creates an api for loading scripts and executing functions.
 * All categories have mapped expressions in the form of
 * 'load{label}Script' for scripts and 'applyFor{label}' for function
 *
 *  Essential: 'C0001'
 *  Performance: 'C0002'
 *  Functional: 'C0003'
 *  Targeting: 'C0004'
 *  SocialMedia: 'C0005'
 *
 * @returns {undefined}
 *
 */
const enableConsentFilter = () => {
    window.userConsent = { loadScript, waitFor };
    Object.keys(Groups).map( key => {
        let loadFn = curryN(3, applyConsentFunc)(Groups[key], loadScript);;
        let applyFn = curryN(2, applyConsentFunc)(Groups[key]);
        let isAprovedFn = curryN(2, isAprovedConsentFunc)(Groups[key]);


/////////////////
//////////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////

        window.userConsent[`load${key}Script`] = loadFn;
        window.userConsent[`applyFor${key}`] = applyFn;
        window.userConsent[`is${key}Approved`] = isAprovedFn;
    });

    /*
    * window.userConsent.is[GroupKey]Approved takes a async callback(response) 
    * response is null if user did not approve, ...or if Approved, window.OnetrustActiveGroups are returned
    *
    * Example how to use it for Targeting Group (Show Targeting Ads):
    * window.userConsent.isTargetingApproved( async (response) => {
    *  if (response) { 
    *      go ahead you are approved for Targeting actions 
    *  } else {
    *      do actions for Non-Approved Targeting
    *  }     
    * });
    */

};

export default enableConsentFilter;