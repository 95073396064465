import otIds from './onetrust.json';

const onetrustFallbackSite = () => {
	const sites = Object.keys(otIds);
	const fallbackSiteId = sites?.[0];

	if (!fallbackSiteId) {
		console.error('Invalid otId configuration');
	}

	return fallbackSiteId;
}

const hasOtIdForSite = site => Object.keys(otIds).some( otSite => site.includes(otSite));

const getOnetrustIdForSite = site => {
	const targetSite = Object.keys(otIds).filter( otSite => site.includes(otSite))
	return otIds && otIds[targetSite] ? otIds[targetSite] : site;
}

const getOnetrustID = (site = window.location.host)  => {
	let otId;
	if (hasOtIdForSite(site)) {
		otId = getOnetrustIdForSite(site);
	}
	else {
		const fallbackSite = onetrustFallbackSite();
		otId = otIds[fallbackSite];
		console.warn(`Failed to retrieve otId for ${site}, using ${fallbackSite} as fallback with id ${otId}`)
		if (!otId) {
			throw Error('Missing ot configuration')
		}
	}

	return otId;
}

export default getOnetrustID;