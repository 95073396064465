/**
 * Returns a promise that waits for an expression to be true - or fails after `timeout` ms. Once
 * `predicate()` is truthy, the promise is resolved with the returned value. Example use case: wait
 * for a global variable to become available.
 *
 * @param {() => any} predicate
 * @param {{
 *    timeout?: number       // Timeout in ms, 0 means no timeout.
 *    checkInterval?: number
 * }} options
 * @return {Promise<any>}
 */
export default function waitFor(predicate, { interval = 50, timeout = 10000, timeoutError = "timeoutError" } = {}, responseFunc) {
    let intervalId
    let result
    let currentState = predicate()

    const resolveResponse = (val) => responseFunc && typeof responseFunc === 'function' ? responseFunc() : val

    if (currentState) {
        result = Promise.resolve(resolveResponse(currentState));
    } else {
        result = Promise.race([
            new Promise((_, reject) => intervalId = setTimeout(reject, timeout, timeoutError)),
            new Promise((resolve) => {
                intervalId = setInterval(() => {
                    let val = predicate()
                    if (val) {
                        resolve(resolveResponse(val))
                    }
                }, interval)
            }),
        ]).finally(() => clearInterval(intervalId))
    }
    return result
}
