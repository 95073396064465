import styles from "./onetrust.css";
import {getElementByAttributeMatch} from "../utils/html";
import {getSiteName} from "../utils/utils";

/**
 * Retrieves the script tag that contains a src attribute which contain 'consent'.
 * Our custom styling is applied if the script element contain a data attribute 'cssoveride'
 * which contain the current domain
 */
export const overrideStyling = () => {
	const element = getElementByAttributeMatch('script', 'src', 'consent');
	const siteName = getSiteName()
	const {cssoverride = ''} = element.dataset;

	if (cssoverride.includes(siteName)) {
		styles.use();
 }
 else {
 		console.warn(`No CSS override applied for ${siteName}`)
 }
}

export default overrideStyling;