/**
 * Creates a new script element and appends it to the head
 *
 * @param {string} src
 * @param {{
 *    async?: boolean
 *    defer?: boolean
 * }} attributes
 * @return {Promise<any>}
 */

const loadScript = async (src, { async = false, defer = true, footer = false, referrerpolicy, ...args } = {}) => {
        return new Promise((resolve, reject) => {
                const el = document.createElement('script');
                el.src = src;
                el.defer = defer;
                el.async = async;
                if(referrerpolicy) {
                  el.referrerPolicy = referrerpolicy;                  
                }
                Object.keys(args).map(key => {
                  const dataValue = args[key];
                  if (typeof dataValue === 'undefined') {
                    throw(`Invalid value for ${key}`);
                  }
                  return el.dataset[key] = dataValue;
                });
                el.onload = () => resolve()
                el.onerror = (e) => reject(`Load for ${src} failed: ${e}`)
                if (footer) {
                  typeof document.getElementsByTagName('footer') !== 'undefined' ? document.getElementsByTagName('footer')[0].appendChild(el) : console.error(`footer is not available for applying load script`);
                } else {
                  document.head.appendChild(el);
                }
                
        })
        .catch( e => {
              console.log(e);
        });
}

export default loadScript;