/**
 * Retrieves a specific html tag from partly matching a data attribute
 * @param tag {string} requested html element
 * @param targetAttr {string} The attribute to use for comparision.
 * @param search {string} the search string which is partly compared
 * @return {object} The identified tag or undefined'
 */
import {mapObjIndexed} from "ramda";

export const getElementByAttributeMatch = (tag, targetAttr, search) => {
	const elements = document.getElementsByTagName(tag);
	const result = [...elements].filter( element => {
		const targetAttrValue = element.getAttribute(targetAttr) ? element.getAttribute(targetAttr) : '';
		return targetAttrValue && targetAttrValue.includes(search);
	});

	return result && result[0] ? result[0] : undefined
}