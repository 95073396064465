export const applyFn = (fn = () => {}) => {
    if (typeof fn === 'function') {
        fn();
}}

export const random = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const isDebug = () => window.location.search.includes('debug');

//////////////
/////////////////////////////////////////////////////////////////////////////
/////////


/**
 * Get the alphabet position of a character
 * @param {string} char
 * @returns {number} alphabet position or -1 if unknown
 */
export const characterPosition = char => {
    const code = char.toUpperCase().charCodeAt(0)
    return code > 64 && code < 91 ? (code - 64) - 1 : -1;
}

export const once = (func) => {
    let result;

    return function () {
        if (func) {
            result = func.apply(this, arguments);
            func = null;
        }

        return result;
    }
};

export const getSiteName = (fallback = 'hant') => {
    const pattern = /\.(\w{4,})\./;
    const match = window.location.host.match(pattern);
    return match && match[1] ? match[1] : fallback;
}