import { awaitConsent, awaitConsentChoice } from './onetrust'
import { once } from "../utils/utils";

/**
 * Ensure that we have consent consent. The function is omnipotent - since it's wrapped in `once` - it
 * can be called multiple times, and the same Promise will always be returned - and resolved or
 * rejected when and if the user has given or refused consent to storing cookies.
 * Singleton promise that tells whether or/when we have consent from the OneTrust consent manager.
 *
 * @return {Promise}
 */
export const hasConsent = once(async (...args) => {
    try {
        const groups = await awaitConsent(args);
        return groups
    } catch (err) {
        if (err === "timeoutError") {
            console.warn('Failed to get consent due to timeout')
        }else{
            console.error(`[ERROR]: Failed to get consent: `, err);
            throw `Failed to get consent for ${args.join(', ')}`
        }
    }
})

/**
 * Promise that tells whether we have consent from the OneTrust consent manager or Not.
 *   
 * This tells is if user has given or refused consent to storing cookies according to given (args) group.
 *  Essential: 'C0001'
 *  Performance: 'C0002'
 *  Functional: 'C0003'
 *  Targeting: 'C0004'
 *  SocialMedia: 'C0005'
 *
 * @return {Promise} Response includes Approved Groups, or null if declined
 */
export const hasConsentOrNot = async (...args) => {
    try {
        const groups = await awaitConsentChoice(args);
        return groups
    } catch (err) {
        if (err === "timeoutError") {
            console.warn('Failed to get consent due to timeout')
        }else{
            console.error(`[ERROR]: Failed to get consent: `, err);
            throw `Failed to get consent for ${args.join(', ')}`
        }
    }
}

