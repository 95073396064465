import {once} from "../utils/utils";

/** @enum {string} Readystates */
export const readyStates = {
    Interactive: 'interactive',
    Complete: 'complete'
};

export const domContentLoaded = once(async () => {
    return new Promise((resolve) => {
        if (document.readyState === readyStates.Interactive || document.readyState === readyStates.Complete) {
            return resolve();
        }
        else {
            document.addEventListener('DOMContentLoaded', () => {
                return resolve();
            });
        }
    })
});