import enableConsentFilter from "./consentFilter";
import loadScript from "../utils/loadscript";
import getOnetrustID from "../onetrust";
import {domContentLoaded} from "../utils/domContentLoaded";
//////////////
///////////////////////////////////////
/////////////////////////////////////////////////////
/////////


/////////
///////////////////////////////////////////////////
/////////

////////////
//////////////////////////////////////////////////
/////////

import {overrideStyling} from "../otstyling";

const main = async () => {

//////////////
///////////////////////
///////////

////////////////
////////////////
///////////////////////
///
///////////
  

  const onetrustID = window.onetrustId ? window.onetrustId : getOnetrustID();
  const stubOnFooter = window.stubOnFooter ? window.stubOnFooter: false;

  overrideStyling()
  if (stubOnFooter) {
    await domContentLoaded();
  }

/////////////////////////////////
///////
////////////////////////////////////////////////////////////////////////////////
///////////////////
///////////////////
///////////////////////////
/////////////////////////////////
//////////////////////////////////
///////
///
/////////////
/////////////////////
///
///////////
  
  enableConsentFilter();
  window.userConsent.loadScript = loadScript;
}
let allerUserConsent = window.allerUserConsent || {};
allerUserConsent.queue = allerUserConsent.queue || [];

const allerUserConsentQueue = () => {
  window.allerUserConsent.queue = {};

  window.allerUserConsent.queue.push = function (callback) {
    callback();
  };

  Object.freeze(window.allerUserConsent);
}

const allerUserConsentLib = async () => {
  await main().then(() => {});
  if (window.allerUserConsent == null) {
    window.allerUserConsent = {};
    allerUserConsentQueue();

    return window.allerUserConsent;
  }

  if (typeof allerUserConsent == 'object' && Array.isArray(window.allerUserConsent.queue)) {
    window.allerUserConsent.queue.forEach((callback) => callback());
    allerUserConsentQueue();

    return window.allerUserConsent;
  }
}

allerUserConsent = (async () => {
  const allerUserConsentApi = await allerUserConsentLib();
  return allerUserConsentApi;
})();