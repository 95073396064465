import waitFor from '../utils/waitFor'
import log from "../utils/log"


/** @typedef{string} Group */
/** @enum {Group} */
export const Groups = {
    Essential: 'C0001',
    Performance: 'C0002',
    Functional: 'C0003',
    Targeting: 'C0004',
    SocialMedia: 'C0005',
}

const requiredTargetingGroups = [
    Groups.Essential,
    Groups.Performance,
    Groups.Functional,
    Groups.Targeting,
]

const hasRequiredGroups = (/** @type {Group[]} */ required, /** @type {Group[]} */ active) =>
    active.length >= required.length && [...required].every((g) => active.includes(g))

const parseActiveGroupsString = (/** @type {string} */ groups) =>
    (groups || '').split(',').filter(group => group.length > 0)


    
const hasRequiredGroupsLoaded = (requiredGroups) => {

    const activeGroups = parseActiveGroupsString(window.OnetrustActiveGroups || '')    
    return hasRequiredGroups(requiredGroups, activeGroups) ? activeGroups : null    
}

// Check if CMP Banner is closed which means user has seen/taken action on the consent choices
const isOTBannerClosed = () => !!(document && document.cookie && document.cookie.indexOf("OptanonAlertBoxClosed=") >= 0);

const areGroupsLoaded = () => !!(window.OnetrustActiveGroups);


// const setStyle = (el, css) => el.setAttribute('style', `${el.getAttribute('style')};${css}`);

// export const setBodyStyle = async (style) => {
//     domContentLoaded();
//     const bodyElem = document.getElementsByTagName('body')[0];
//     if (bodyElem) {
//         setStyle(bodyElem, style);
//     }
//     else {
//         console.error(`initNoneConsent:: body is not available for applying style ${style}`);
//     }
// }
// // Freeze scrolling page when cmp box is shown
// function handleClosedBanner() {
//     const options = isOTBannerClosed() ?
//     {style: `overflow: initial`, timeout: 1000} : {style: `overflow: hidden`, timeout: 0};
//     setTimeout(() => setBodyStyle(options.style), options.timeout);
// }
// function OptanonWrapper() {
//     handleClosedBanner();
// }
// // Fallback if OTBannerClosed cookie already is set
// handleClosedBanner();


export async function awaitConsentChoice ( requiredGroups = requiredTargetingGroups, timeout = 60 * 1e3 ) {

    log('awaitConsentChoice wait for BannerClosed && isOTGroupsLoaded ', requiredGroups)

    const hasUserChoice = () => {
        const choice = isOTBannerClosed() && areGroupsLoaded()
        if(choice) {
            log('areGroupsLoaded!!')
        }
        return choice
    }

    // Check if we have consent by match the requiredGroups
    return await waitFor( hasUserChoice, { timeout}, () => hasRequiredGroupsLoaded(requiredGroups) )
}
    
/**
 * Wait for OneTrust consent groups
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {Group[]} requiredGroups - Consent groups required, default to all groups
 * @param {number} timeout
 * @return {Promise<Group[]>} Brief description of the returning value here.
 */
export async function awaitConsent( requiredGroups = requiredTargetingGroups, timeout = 60 * 1e3 ) { 
    return await waitFor(
        () => hasRequiredGroupsLoaded(requiredGroups),
        { timeout }
    )
}